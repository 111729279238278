import {
  blackAlpha700,
  blue100,
  blue50,
  blue500,
  blueDark500,
  gray500,
  gray600,
  green100,
  green500,
  greenDark800,
  green400,
  pinkDark100,
  pinkDark600,
  pinkDark800,
  purple50,
  purpleDark500,
  purpleDark800,
  whiteBase,
  yellow100,
  yellow500,
  blue200,
  blue800,
  gray1050,
  gray1100,
  gray1300,
  pinkDark200,
  purpleDark100,
  yellow800,
  yellowLight50,
  pinkDark700,
  blackBase,
  gray1400,
  gray1200,
  gray1000,
  gray700,
  gray900,
  purpleLight100,
  yellow700,
  greenLight50,
  yellowLight100,
  gray800,
  green300,
  purpleLightAlpha100,
  purpleDark300,
  neutralAlpha700,
  neutralAlpha900,
  purpleDark200,
  gray100,
  purpleLight200,
  blue700,
  pinkDark300,
  purple100,
  purpleDark700,
  purpleDark600,
  blue600,
  purple800,
  whiteAlpha300,
  whiteAlpha700,
  whiteAlpha800,
  purpleDark50,
} from '../darkPalette';
import type { Colors } from '../colors.types';

/** IMPORTANT! Any changes to dark needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in colorTokenToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the dark theme class framework colors in globals.css
 */
export const dark: Colors = {
  bg: gray1300,
  bgStrong: gray1400,
  bgOverlay: blackAlpha700,
  surface: gray1200,
  surfaceHover: gray1050,
  surfaceActive: gray1200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purpleLight200,
  surfaceRaised: gray1100,
  surfaceSunken: gray1400,
  surfaceOverlay: gray1100,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  highContrast: blackBase,
  border: gray900,
  borderWeak: gray1100,
  borderWeaker: gray1200,
  borderStrong: gray700,
  borderBrandWeakest: blue100,
  borderPrimaryWeakest: gray800,
  borderNegativeWeakest: pinkDark100,
  borderNeutralWeakest: gray900,
  borderInfoWeakest: purpleLight100,
  borderPositiveWeakest: green100,
  borderNoticeWeakest: yellow100,
  borderSecondaryWeakest: purpleDark100,
  borderDisabled: gray1100,
  borderTransparent: whiteAlpha300,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  text: gray100,
  textLink: whiteBase,
  textWeak: whiteAlpha800,
  textWeaker: whiteAlpha700,
  textNegative: pinkDark700,
  textPrimary: gray100,
  textDisabled: gray900,
  onBrand: whiteBase,
  onBrandWeak: blue800,
  onBrandWeakest: blue800,
  onDisabled: gray700,
  onDisabledStrong: gray700,
  onInfo: whiteBase,
  onInfoWeak: purple800,
  onInfoWeakest: purpleDark800,
  onInteractive: whiteBase,
  onHighContrast: gray100,
  onNeutral: whiteBase,
  onNeutralWeak: whiteBase,
  onNeutralWeakest: whiteBase,
  onNegative: whiteBase,
  onNegativeWeak: pinkDark800,
  onNegativeWeakest: pinkDark800,
  onNotice: blueDark500,
  onNoticeWeak: yellow700,
  onNoticeWeakest: yellow800,
  onPositive: blueDark500,
  onPositiveWeak: greenDark800,
  onPositiveWeakest: greenDark800,
  onPrimary: gray1300,
  onPrimaryWeak: gray1300,
  onPrimaryWeakest: gray1300,
  onSecondary: whiteBase,
  onSecondaryWeak: purple800,
  onSecondaryWeakest: purple800,
  primary: gray100,
  primaryWeak: gray600,
  primaryWeakest: gray700,
  info: purpleDark500,
  infoWeak: purpleLight200,
  infoWeakest: purpleDark50,
  neutral: gray1000,
  neutralWeak: gray1100,
  neutralWeakest: gray1100,
  negative: pinkDark600,
  negativeWeak: pinkDark200,
  negativeWeakest: pinkDark100,
  positive: green500,
  positiveWeak: green100,
  positiveWeakest: greenLight50,
  secondary: purpleDark500,
  secondaryWeak: purpleLight100,
  secondaryWeakest: purple50,
  notice: yellow500,
  noticeWeak: yellowLight100,
  noticeWeakest: yellowLight50,
  disabled: gray1000,
  disabledStrong: gray900,
  interactiveFocused: purpleLightAlpha100,
  // COMPONENT TOKENS
  buttonBgBrand: blue500,
  buttonBgBrandHover: blue600,
  buttonBgBrandActive: blue700,
  buttonBgNegative: pinkDark600,
  buttonBgNegativeHover: pinkDark300,
  buttonBgNegativeActive: pinkDark200,
  buttonBgNegativeWeak: pinkDark100,
  buttonBgNegativeWeakHover: pinkDark200,
  buttonBgNegativeWeakActive: pinkDark300,
  buttonBgOutlineHover: gray1000,
  buttonBgOutlineActive: gray900,
  buttonBgOutlineChecked: purpleDark100,
  buttonBgOutlineCheckedHover: purple100,
  buttonBgOutlineCheckedActive: purple50,
  buttonBgPositive: green500,
  buttonBgPositiveHover: green400,
  buttonBgPositiveActive: green300,

  buttonBgPrimary: gray500,
  buttonBgPrimaryHover: gray600,
  buttonBgPrimaryActive: gray700,
  buttonBgPrimaryChecked: purpleDark700,
  buttonBgPrimaryCheckedHover: purpleDark600,
  buttonBgPrimaryCheckedActive: purpleDark500,

  buttonBgSecondaryHover: gray1000,
  buttonBgSecondaryActive: gray900,
  buttonBgSecondaryChecked: purpleDark100,
  buttonBgSecondaryCheckedHover: purpleDark200,
  buttonBgSecondaryCheckedActive: purpleDark300,
  buttonBgTertiary: gray900,
  buttonBgTertiaryHover: gray1000,
  buttonBgTertiaryActive: gray1100,
  buttonBgTertiaryChecked: purpleDark700,
  buttonBgTertiaryCheckedHover: purpleDark600,
  buttonBgTertiaryCheckedActive: purpleDark500,
  buttonBgPrimaryReverse: gray1200,
  buttonBgPrimaryReverseHover: gray1300,
  buttonBgPrimaryReverseActive: gray1400,
  buttonBgPrimaryReverseChecked: purpleDark500,
  buttonBgPrimaryReverseCheckedHover: purpleDark300,
  buttonBgPrimaryReverseCheckedActive: purpleDark200,
  inputBg: gray1100,
  inputBgActive: gray1200,
};
