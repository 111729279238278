import { toRgb, type Colors } from '@mentimeter/ragnar-colors';

function getRGBAColor(rgbString: string): string {
  if (!rgbString) throw new Error();
  const { r, g, b, alpha } = toRgb(rgbString);
  return `rgb(${r} ${g} ${b} / ${alpha !== undefined ? alpha : 1})`;
}

function formatPaletteColorName(colorName: string): string {
  const formattedColorName = colorName.replace(
    /([A-Z])|(\d+)/g,
    (_match: any, letter: string, number: number) => {
      if (number) {
        return `-${number}`;
      } else {
        return `-${letter.toLowerCase()}`;
      }
    },
  );
  return formattedColorName;
}

export function getThemeColorVariables(colors: Colors) {
  const cssVariables = {} as Record<string, string>;
  for (const colorName in colors) {
    const color = colors[colorName as keyof Colors];
    const cssVariableName = `--color-${formatPaletteColorName(colorName)}`;
    try {
      cssVariables[cssVariableName] = getRGBAColor(color);
    } catch (error) {}
  }
  return cssVariables;
}
