import type {
  DevelopmentFlagKey,
  DevelopmentFlagValue,
} from './developmentFlags';
import { getDevelopmentFlagOverrides } from './getDevelopmentFlagOverrides';
import { isValidDevelopmentFlagValue } from './isValidDevelopmentFlagValue';

export function getDevelopmentFlag<T extends DevelopmentFlagKey>(
  flagKey: T,
): DevelopmentFlagValue<T> | undefined {
  const value = getDevelopmentFlagOverrides()[flagKey];

  if (isValidDevelopmentFlagValue(flagKey, value)) {
    return value;
  }

  return undefined;
}
