'use client';

import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { handleNetworkError } from './networkErrorIntercept';

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export interface ConfigT {
  baseURL?: string | null | undefined;
  token?: string | null | undefined;
  voterToken?: string | null | undefined;
  timeout?: number | undefined;
}

export type ClientBaseT<ClientT> = ClientT & {
  get: AxiosInstance['get'];
  request: AxiosInstance['request'];
  delete: AxiosInstance['delete'];
  head: AxiosInstance['head'];
  options: AxiosInstance['options'];
  post: AxiosInstance['post'];
  put: AxiosInstance['put'];
  patch: AxiosInstance['patch'];
  interceptors: AxiosInstance['interceptors'];
  defaults: AxiosInstance['defaults'];
  getUri: AxiosInstance['getUri'];
  getBaseURL: () => string | undefined;
};

export const exposeDefaultClientApi = (
  client: AxiosInstance,
): ClientBaseT<Record<string, unknown>> => ({
  get: client.get,
  defaults: client.defaults,
  interceptors: client.interceptors,
  request: client.request,
  delete: client.delete,
  head: client.head,
  options: client.options,
  getUri: client.getUri,
  patch: client.patch,
  post: client.post,
  put: client.put,
  getBaseURL: () => {
    return client.defaults.baseURL;
  },
});

interface ExtraArguments extends ConfigT {
  defaultHeaders: typeof defaultHeaders;
}

export function createClientCreator<ClientT>(
  extend: (instance: AxiosInstance, args: ExtraArguments) => ClientT,
): (arg0: ConfigT) => ClientBaseT<ClientT> {
  return function createClient({ baseURL, token, voterToken, timeout = 0 }) {
    if (!baseURL) {
      throw new Error('missing baseURL in createClient');
    }

    const headers = {
      ...defaultHeaders,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };

    const client = axios.create({
      baseURL,
      headers,
      timeout,
    });

    // Intercept network response errors to decide whether they should be sent to sentry or datadog
    client.interceptors.response.use(
      (response) => response,
      handleNetworkError,
    );

    const extraArguments: ExtraArguments = {
      baseURL,
      token,
      voterToken,
      defaultHeaders,
    };

    return {
      ...extend(client, extraArguments),
      ...exposeDefaultClientApi(client),
    };
  };
}
