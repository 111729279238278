import React from 'react';
import { TailwindTheme } from '@mentimeter/ragnar-tailwind-config';
import { ThemeProvider as FelaThemeProvider } from 'react-fela';
import { type DSC } from '@mentimeter/ragnar-dsc';

export const ThemeProvider = ({
  theme,
  themeClass = '',
  children,
  overwrite = false,
}: {
  theme: Partial<DSC>;
  themeClass?: string | undefined;
  children: React.ReactNode;
  overwrite?: boolean;
}) => {
  const felaTheme = (
    <FelaThemeProvider overwrite={overwrite} theme={theme}>
      {children}
    </FelaThemeProvider>
  );

  const id = React.useId();
  const classId = id.replace(/:/g, ''); // useId adds colons to the id, which is not allowed in css classes

  const fontCSSVariables = theme.fonts
    ? `--font-family-body: ${theme.fonts.body};\n--font-family-heading: ${theme.fonts.heading};\n`
    : '';

  // Only use TailwindTheme if we have colors and no static explicit class to set
  if (theme?.colors && !themeClass) {
    return (
      <TailwindTheme colors={theme?.colors} fontCSSVariables={fontCSSVariables}>
        {felaTheme}
      </TailwindTheme>
    );
  }

  if (theme.fonts) {
    return (
      <>
        <style>{` .fonts-${classId} {${fontCSSVariables}}`}</style>
        <div
          id={id}
          style={{ display: 'contents' }}
          className={`fonts-${classId} ${themeClass}`}
        >
          {felaTheme}
        </div>
      </>
    );
  }

  return (
    <div style={{ display: 'contents' }} className={themeClass}>
      {felaTheme}
    </div>
  );
};
