import type { Regions, ClientBaseT } from '@mentimeter/http-clients';

export class RegionHttpClients<C> {
  private activeRegion: Regions = 'eu';
  private clients: Record<Regions, ClientBaseT<C>>;

  constructor(clients: Record<Regions, ClientBaseT<C>>) {
    this.clients = clients;
  }

  public setDefaultClientByRegion(region: Regions) {
    // Check if client exists and fallback if undefined
    if (this.clients[region || 'eu']) {
      this.activeRegion = region;
      return this;
    }
    throw new Error(`Missing client for region: ${region}`);
  }

  public getClientByRegion(region: Regions) {
    const client = this.clients[region || 'eu'];
    // Check if client exists and fallback if undefined
    if (client) {
      return client;
    }
    throw new Error(`Missing client for region: ${region}`);
  }

  public get client() {
    return this.clients[this.activeRegion];
  }
}
