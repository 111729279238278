import React, { type PropsWithChildren } from 'react';
import type { Colors } from '@mentimeter/ragnar-colors';
import { getThemeColorVariables } from './utils';

/**
 *
 * @returns style tag with theme color css variables based on the provided colors
 */

export function TailwindTheme({
  colors,
  fontCSSVariables,
  children,
}: PropsWithChildren<{ colors: Colors; fontCSSVariables: string }>) {
  const themeColorVariables = getThemeColorVariables(colors);
  const id = React.useId();
  const classId = id.replace(/:/g, ''); // useId adds colons to the id, which is not allowed in css classes
  return (
    <>
      <style>
        {` .theme-${classId} {
        ${Object.entries(themeColorVariables).reduce(
          (acc, [variable, color]) => acc + `${variable}: ${color};\n`,
          '',
        )}
        ${fontCSSVariables}
      }`}
      </style>
      {/* id is required to ensure that useId is unique */}
      <div
        id={id}
        style={{ display: 'contents' }}
        className={`theme-${classId}`}
      >
        {children}
      </div>
    </>
  );
}
