declare global {
  interface Window {
    dataLayer: any;
    ga: any;
  }
}

function gaTrackAll(...args: (string | undefined)[]) {
  if (typeof ga !== 'undefined' && ga?.getAll)
    for (let l, a, e = ga.getAll(), g = 0; g < e.length; g++)
      // eslint-disable-next-line no-sequences, @typescript-eslint/no-unused-expressions
      (l = e[g]!.get('name') + '.send'),
        (a = Array.prototype.slice.call(args)).unshift(l),
        a.splice(1, 1),
        ga.apply(window, a as any);
}

// Documentation
// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export const gaTrack = ({
  event = 'event',
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
}: {
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: string;
}) =>
  typeof window !== 'undefined' &&
  gaTrackAll('send', event, eventCategory, eventAction, eventLabel, eventValue);

export const gtmTrack = ({
  event,
  ...data
}: {
  event: string;
  [key: string]: any;
}) =>
  void typeof window !== 'undefined' &&
  window.dataLayer?.push?.({ event, ...data });
