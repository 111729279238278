'use client';

import { gaTrack } from '@mentimeter/google-tracking';
import type { AxiosPromise } from 'axios';
import { getDevelopmentFlag } from '@mentimeter/development-flags';
import type {
  QuestionSlideType,
  QuestionType,
  Reaction,
  Series,
} from '../core/types';
import { createClientCreator } from '../createClientCreator';

type TrackingProperty = number | boolean | string | string[] | undefined | null;
export interface TrackingPayload {
  event: string;
  properties?:
    | {
        [key: string]:
          | TrackingProperty
          | Record<any, TrackingProperty>
          | Record<any, TrackingProperty>[];
      }
    | undefined;
  trackables?: unknown;
  options?: unknown;
}

export interface SalesForceCreateLeadPayload {
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null | undefined;
  Inquiry_Type__c: string;
  description: string | null | undefined;
  source: 'Enterprise page';
  Office_Location__c: string;
  Campaign_Name__c?: string | undefined;
  Marketing_medium__c?: string | undefined;
  Marketing_source__c?: string | undefined;
}

export interface StartEnterpriseTrialSFPayload {
  lastName: string;
  email: string;
  source: 'Enterprise trial';
  company: 'Not provided';
  status: 'Prospected';
  CurrencyIsoCode: 'USD';
}

export interface CreateConversationPayload {
  leadId?: string;
  email: string;
  subject?: string;
  message: string;
}

export interface CreateConversationResponse {
  type: string;
  id: string;
  created_at: number;
  body: string;
  message_type: string;
  conversation_id: string;
}

export interface AssignConversationPayload {
  conversationId: string;
}

export interface AssignConversationResponse {
  id: string;
}

export interface TagLeadPayload {
  leadId: string;
}

interface GlobalVoteProperties {
  'vote key': string;
  'interaction source': string | null;
}

export interface JoinedPresentationProperties {
  'join mode': 'qrcode' | 'votecode' | 'link';
  context?: string;
  'vote key'?: string;
  'participation identity mode': Series['participation_identity_mode'];
}

export interface CreateVoterEvent<
  E extends string,
  T extends Record<string, any> = never,
> {
  event: E;
  properties?: T;
}

export type VoteTrackPayload =
  | CreateVoterEvent<
      'Clicked reaction button',
      {
        'Presentation id': string | undefined;
        'reaction type': Reaction;
        'question type': QuestionType | undefined;
        'slide type': QuestionSlideType | undefined;
      }
    >
  | CreateVoterEvent<'Clicked go to slide', { 'question type': QuestionType }>
  | CreateVoterEvent<
      'Sent comment',
      { 'comment type': 'Suggestion' | 'Free text' }
    >
  | CreateVoterEvent<'Joined presentation', JoinedPresentationProperties>
  | CreateVoterEvent<
      'Submitted code',
      { status: 'success' | 'closed' | 'fail' }
    >
  | CreateVoterEvent<'Viewed end screen'>
  | CreateVoterEvent<'Clicked sign up', { context: 'End screen' }>
  | CreateVoterEvent<
      'Clicked join presenters workspace',
      {
        'vote key': string;
        'workspace id': number | null;
      }
    >
  | CreateVoterEvent<'Clicked get results'>
  | CreateVoterEvent<'Clicked join other presentation'>
  | CreateVoterEvent<'Clicked footer link'>
  | CreateVoterEvent<'Opened terms'>
  | CreateVoterEvent<'Upvoted QFA'>
  | CreateVoterEvent<'Clicked join quiz'>
  | CreateVoterEvent<'Upvoted open ended'>
  | CreateVoterEvent<
      'Opened integration application',
      {
        'vote key': string;
        integration: 'MsTeams';
      }
    >
  | CreateVoterEvent<'Identified responses form submitted'>;

export type Track<Payload extends TrackingPayload = TrackingPayload> = (
  payload: Payload,
) => void;

export type TrackVisitor = (
  payload: TrackingPayload,
  visitorToken: string,
) => Promise<void>;

export type TrackProperty = (payload: Record<string, unknown>) => Promise<void>;

export type SalesForceCreateLead = (
  payload: SalesForceCreateLeadPayload,
) => AxiosPromise<void>;

export type EnterpriseTrialSalesForceCreateLead = (
  payload: StartEnterpriseTrialSFPayload,
) => AxiosPromise<void>;

export type CreateConversation = (
  payload: CreateConversationPayload,
) => AxiosPromise<CreateConversationResponse> | never;

export type AssignConversation = (
  payload: AssignConversationPayload,
) => AxiosPromise<AssignConversationResponse>;

export type TagLead = (
  payload: TagLeadPayload,
) => AxiosPromise<Record<string, unknown>>;

export type TrackVoter = (payload: VoteTrackPayload) => Promise<void>;
export type SetGlobalProperties = (payload: GlobalVoteProperties) => void;

export enum TrackingPlacement {
  QuickCreate = 'Quick create',
  Overview = 'Overview',
  CreateThemeButton = 'CreateThemeButton',
  ExportExcel = 'ExportExcel',
  CustomizeSlide = 'CustomizeSlide',
  QuickForm = 'QuickForm',
  PaceSettings = 'Pace settings',
  SharedWithMeMenuItem = 'Shared with me menu item',
  SharedTemplatesMenuItem = 'Shared templates menu item',
  WorkspacePresentationsMenuItem = 'Workspace presentations menu item',
  UserHomeMenuItem = 'User home menu item',
  MyPresentationsMenuItem = 'My presentations menu item',
  EngagementLimitsProgressBar = 'Engagement limits progress bar',
  EngagementLimitsGracePeriod = 'Engagement limits grace period popover',
  EngagementLimitsPresentButtonPaywall = 'Engagement limits present button paywall',
  CollaborationTab = 'Collaboration tab',
  ResultsTab = 'Results tab',
  MembersTable = 'Members table',
  ResetResultsModal = 'Reset results modal',
  SharedWithMeUpgradeNudge = 'Shared with me upgrade nudge',
  QfaSettingsPopover = 'QFA settings popover',
  MentiInteractivitySettingsQfaModeration = 'Menti interactivity settings QFA moderation',
  MentiInteractivitySettingsParticipantNames = 'Menti interactivity settings Participant names',
  QfaSlideContentSettings = 'QFA slide content settings',
  RatingSlideCustomGrids = 'Rating slide custom grids',
  QfaSlideModerationSetting = 'QFA slide moderation setting',
  AIFeatureAtCapacityModal = 'AI feature at capacity modal',
  DashboardRowMenu = 'Dashboard row menu',
  ImportDocumentQuickCreate = 'Import document quick create',
}

export interface TrackingClient {
  trackVisitor: TrackVisitor;
  trackVoter: TrackVoter;
  setUserProperty: TrackProperty;
  createConversation: CreateConversation;
  assignConversation: AssignConversation;
  tagLead: TagLead;
  salesForceCreateLead: SalesForceCreateLead;
  EnterpriseTrialSalesForceCreateLead: EnterpriseTrialSalesForceCreateLead;
  setGlobalProperties: SetGlobalProperties;
  globalProperties: Partial<GlobalVoteProperties>;
}

function logAndRethrowError(err: Error): never {
  gaTrack({
    eventCategory: 'Error',
    eventAction: 'Call Tracking API',
    eventLabel: JSON.stringify(err),
  });

  throw err;
}

const toBase64String = (data: Record<string, any>) =>
  Buffer.from(JSON.stringify(data)).toString('base64');

const logTrackingForTestingPurposes = (
  trafficType: 'user' | 'voter' | 'visitor',
  payload: TrackingPayload,
) => {
  try {
    if (
      localStorage.getItem('console-tracking') === 'true' ||
      getDevelopmentFlag('console-tracking') === 'on'
    ) {
      // eslint-disable-next-line
      console.log(
        `tracking "${trafficType}":`,
        JSON.stringify(payload, null, 4),
      );
    }
  } catch (error) {
    // localStorage is prone to throwing errors, but we dont care about them:
    // this logging block is for testing only
  }
};

export const createTrackingClient = createClientCreator<TrackingClient>(
  (client, { baseURL, token, voterToken }) => {
    return {
      globalProperties: {},
      async trackVisitor(payload, visitorToken) {
        const encodedData = toBase64String(payload);

        logTrackingForTestingPurposes('visitor', payload);

        try {
          if ('sendBeacon' in navigator) {
            navigator.sendBeacon(
              `${baseURL}/event/visitor?data=${encodedData}&token=${visitorToken}`,
            );
          } else {
            await client.post(
              `/event/visitor?data=${encodedData}&token=${visitorToken}&missingBeacon=true`,
            );
          }
        } catch (error) {
          // Swallow tracking errors
        }
      },
      setGlobalProperties(properties) {
        this.globalProperties = properties;
      },
      async trackVoter(data) {
        if (!voterToken) return;

        const mergedData = {
          ...data,
          properties: {
            ...this.globalProperties,
            ...data.properties,
          },
        };

        logTrackingForTestingPurposes('voter', mergedData);

        const encodedData = toBase64String(mergedData);
        const payload = { data: encodedData };

        try {
          if ('sendBeacon' in navigator) {
            const blob = new Blob([JSON.stringify(payload)], {
              type: 'application/json',
            });
            navigator.sendBeacon(
              `${baseURL}/event/voter?token=${voterToken}`,
              blob,
            );
          } else {
            await fetch(
              `${baseURL}/event/voter?token=${voterToken}&missingBeacon=true`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              },
            );
            // Deliberately not checking ok, swallowing tracking errors
          }
        } catch (error) {
          // Swallow tracking errors
        }
      },
      async setUserProperty(payload) {
        if (!token) return;

        const encodedData = toBase64String(payload);

        try {
          await client.get(
            `/user/properties?data=${encodedData}&token=${token}`,
          );
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
      async salesForceCreateLead(payload) {
        try {
          return client.post('/salesforce/lead', payload);
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
      async EnterpriseTrialSalesForceCreateLead(payload) {
        try {
          return client.post('/salesforce/lead', payload);
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
      //
      async createConversation(payload) {
        try {
          return client.post('/intercom/conversations', payload);
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
      async assignConversation(payload: AssignConversationPayload) {
        try {
          return client.post('/intercom/conversations/assign', payload);
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
      async tagLead(payload) {
        try {
          return client.post('/intercom/tag', payload);
        } catch (err: any) {
          logAndRethrowError(err);
        }
      },
    };
  },
);
