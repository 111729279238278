import type { Regions } from './core/types';
import {
  createGlobalClient,
  globalRoot,
  type HttpClients,
  type HttpSetup,
} from './global-http-internal';

/**
 * Getter for Core API global HTTP client in voting.
 * TODO: Merge with regular core
 */
export const voting = createGlobalClient('voting');

/**
 * Getter for Core API global HTTP client.
 */
export const core = createGlobalClient('core');

/**
 * Getter for Tracking API global HTTP client.
 */
export const tracking = createGlobalClient('tracking');

export type CreateTrackingClient = typeof tracking;

export function setupHttp(httpSetup: HttpSetup) {
  globalRoot.__mentimeterHttp = httpSetup;
}

export function getGlobalFallbackRegion(clientName: keyof HttpClients) {
  const clientSetup = globalRoot.__mentimeterHttp[clientName];
  if (!clientSetup)
    throw new Error(`HTTP client "${clientName}" is not set up.`);

  return clientSetup.defaultRegion;
}

const allowedRegions: Regions[] = ['eu', 'us'];

/**
 * Set the region that a http client will fall back to if no override prop is provided.
 * @returns A function restoring the defaultRegion to its previous value
 */
export function setClientFallbackRegion(
  clientName: keyof HttpClients,
  region: Regions,
) {
  if (!allowedRegions.includes(region))
    throw new Error(`Missing client for region: ${region}`);

  const clientSetup = globalRoot.__mentimeterHttp[clientName];
  if (!clientSetup)
    throw new Error(`HTTP client "${clientName}" is not set up.`);

  const originalRegion = clientSetup.defaultRegion;

  clientSetup.defaultRegion = region;

  return () => {
    clientSetup.defaultRegion = originalRegion;
  };
}
