import {
  DEVELOPMENT_FLAGS,
  type DevelopmentFlagKey,
  type DevelopmentFlagValue,
} from './developmentFlags';

export function isValidDevelopmentFlagValue<T extends DevelopmentFlagKey>(
  flagKey: T,
  value: unknown,
): value is DevelopmentFlagValue<T> {
  if (typeof value !== 'string') return false;
  const possibleValues: readonly string[] = DEVELOPMENT_FLAGS[flagKey].values;
  return possibleValues.includes(value);
}
